import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const VideoPlayer = ({ user }) => {
  const { id, mediatoken } = useParams();
  const [videoData, setVideoData] = useState(null);
  useEffect(() => {
    const fetchVideoData = async () => {
      try {
        const responseToken = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/mediatoken/authentication/${user.id}/${id}/${mediatoken}`
        );
        const tokenAuthentication = responseToken.data.authenticated;
        if (tokenAuthentication === true) {
          const responseVideo = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/api/v1/challenges/video/${id}`
          );
          setVideoData(responseVideo.data.data.challenge_video);
          await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/v1/viewed_videos`,
            {
              user_id: user.id, // Zamijenite s odgovarajućim korisničkim ID-om
              video_id: id,
            }
          );
        }
      } catch (error) {
        console.error("Error fetching video data:", error);
      }
    };

    fetchVideoData();
  }, [id]);

  return (
    <>
      <section className="bg-zinc-900">
        {/* Video Player */}
        <div className="flex justify-between">
          <div id="video" className="top-0 left-0 h-[100vh] lg:aspect-video">
            {videoData && (
              <iframe
                src={`https://iframe.mediadelivery.net/embed/59823/${videoData.video_id}?autoplay=true`}
                loading="lazy"
                style={{
                  border: "none",
                  position: "absolute",
                  top: 0,
                  height: "100%",
                  width: "100%",
                }}
                allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                allowFullScreen={true}
              />
            )}
          </div>
        </div>
      </section>
      <style>
        {`
          #header, #footer {
            display: none !important;
          }
        `}
      </style>
    </>
  );
};

export default VideoPlayer;
