// SubscriptionModal.jsx
import React, { useState, useEffect } from "react";
import "../../style/style.css";
import { Button, Modal } from "flowbite-react";
import fetchUser from "../../api/fetchUser";
import fetchSubscription from "../../api/fetchSubscription";
import fetchSubscriptionmetabyuser from "../../api/fetchSubscriptionmetabyuser";

const SubscriptionModal = ({ user, show, onClose }) => {
  const [userData, setUserData] = useState(null);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [productData, setProductData] = useState(null);
  const [selectedOption, setselectedOption] = useState("monthly");

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (user) {
          const responseUser = await fetchUser.get(`/${user.id}`);
          setUserData(responseUser.data.data.user);
          const responseSubscription = await fetchSubscriptionmetabyuser.get(
            `/${user.id}`
          );
          setSubscriptionData(responseSubscription.data.data.subscription);
        }
      } catch (err) {}
    };
    fetchData();
  }, [user]);

  const handleOptionSelect = (optionId) => {
    setselectedOption(optionId);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response;
        response = await fetchSubscription.get(`/`);
        setProductData(response.data.data);
      } catch (err) {
        console.error(`Error fetching subscriptions`, err);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <Modal show={show} onClose={onClose}>
        <Modal.Header className="bg-zinc-800 text-white">
          <span className="text-white">Postani Član</span>
        </Modal.Header>
        <Modal.Body className="bg-zinc-800 bg-cover bg-center">
          <div className="w-full grid md:grid-cols-2 ">
            <div
              className="hidden md:block rounded-lg shadow bg-cover bg-center p-4"
              style={{
                backgroundImage: `url(https://medianfiles.com/wp-content/uploads/2024/02/Dizajn-bez-naslova.png)`,
              }}
            >
              <h4 className="font-semibold text-lg hidden">
                Zašto postati član?
              </h4>
              <div className="flex flex-col mt-4 gap-4 hidden">
                <div className="flex gap-4 items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#10b981"
                    viewBox="0 0 1792 1792"
                  >
                    <path d="M1600 736v192q0 40-28 68t-68 28h-416v416q0 40-28 68t-68 28h-192q-40 0-68-28t-28-68v-416h-416q-40 0-68-28t-28-68v-192q0-40 28-68t68-28h416v-416q0-40 28-68t68-28h192q40 0 68 28t28 68v416h416q40 0 68 28t28 68z"></path>
                  </svg>
                  <span className="font-semibold">Pristup platfrmi 0/24</span>
                </div>

                <div className="flex gap-4 items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#10b981"
                    viewBox="0 0 1792 1792"
                  >
                    <path d="M1600 736v192q0 40-28 68t-68 28h-416v416q0 40-28 68t-68 28h-192q-40 0-68-28t-28-68v-416h-416q-40 0-68-28t-28-68v-192q0-40 28-68t68-28h416v-416q0-40 28-68t68-28h192q40 0 68 28t28 68v416h416q40 0 68 28t28 68z"></path>
                  </svg>
                  <span className="font-semibold">
                    Dostupno na svim uređajima
                  </span>
                </div>
              </div>
            </div>
            {productData &&
              productData.subscriptions &&
              productData.subscriptions.length > 0 && (
                <ul
                  className="px-4 text-sm gap-4 font-medium text-gray-500 sm:flex flex flex-col"
                  id="fullWidthTab"
                  data-tabs-toggle="#fullWidthTabContent"
                  role="tablist"
                >
                  <p className="text-sm font-semibold text-zinc-900 hidden">
                    Odaberi jednu od ponuđenih opcija:
                  </p>
                  {productData.subscriptions
                    .sort((a, b) => a.price - b.price)
                    .map((subscription) => (
                      <li
                        key={subscription.subscription_type}
                        className="w-full"
                      >
                        <button
                          id={subscription.subscription_type}
                          data-tabs-target="#stats"
                          type="button"
                          className={`flex items-center justify-between w-full bg-zinc-700 px-4 py-3 rounded-lg hover:bg-zinc-600 focus:outline-none ${
                            selectedOption === subscription.subscription_type
                              ? "border-2 border-zinc-100 bg-zinc-700"
                              : ""
                          }`}
                          onClick={() =>
                            handleOptionSelect(subscription.subscription_type)
                          }
                        >
                          <div className="flex flex-col text-left">
                            <span className="text-sm font-semibold text-white">
                              {subscription.title}
                            </span>
                            <span className="font-normal text-xs text-zinc-200">
                              €
                              {(
                                subscription.price / subscription.duration
                              ).toFixed(2)}{" "}
                              /mj
                            </span>
                          </div>
                          <div className="flex flex-col text-right">
                            {subscription.old_price && (
                              <span className="opacity-50 line-through text-sm font-semibold text-white">
                                €{subscription.old_price}
                              </span>
                            )}
                            <span className="text-sm font-semibold text-white">
                              €{subscription.price}
                            </span>
                          </div>
                        </button>
                      </li>
                    ))}
                  <a
                    className="mt-4 block w-full text-center font-bold m-auto px-12 py-4 text-sm rounded-lg bg-white text-zinc-900"
                    href={`/app/checkout/subscription/${selectedOption}`}
                  >
                    Nastavi dalje
                  </a>
                  <span className="text-center text-xs text-white font-normal">
                    Članarina se <span className="font-bold">automatski</span>{" "}
                    obnavlja na kraju razdoblja naplate.
                  </span>
                </ul>
              )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SubscriptionModal;
