// Login.js
import React from "react";
import "../../style/style.css";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

import LoginBox from "./LoginBox"; // Import the new component

const Login = () => {
  useGSAP(() => {
    gsap.to(".login-content", {
      scale: 1,
      ease: "power3.inOut",
      duration: 2,
    });

    gsap.to(".logo p span", {
      top: "0px",

      ease: "power3.inOut",
      duration: 2,
    });

    gsap.to(".logo-2", {
      left: "0px",
      ease: "power3.inOut",
      duration: 2,
      delay: 1,
    });

    gsap.to(".logo-2", {
      left: "500px",
      ease: "power3.inOut",
      duration: 2,
      delay: 5,
    });

    gsap.to(".logo p span", {
      top: "220px",
      ease: "power3.inOut",
      duration: 2,
      delay: 2,
    });
  });

  return (
    <>
      <LoginBox />
      <div className="logo fixed top-1/2 left-1/2 translate-y-[-120%] translate-x-[-50%] z-40">
        <p
          className="text-white font-bold text-center mx-auto"
          style={{ clipPath: "polygon(0 0, 100% 0, 100% 100%, 0% 100%)" }}
        >
          <span
            className="uppercase text-bison relative text-[5rem] text-second-color"
            style={{ top: "220px" }}
          >
            Better You
          </span>
        </p>
        <div
          className=" flex justify-end relative top-[-20px]"
          style={{ clipPath: "polygon(0 0, 100% 0, 100% 100%, 0% 100%)" }}
        >
          <p className="hidden logo-2 text-second-color italic text-crimson text-white font-bold text-[5rem] relative left-[550px] top-[-10px]">
            Online Gym
          </p>
        </div>
      </div>
      <div className="w-screen h-screen overflow-x-hidden overflow-y-hidden">
        <section
          className="w-full h-full flex items-center justify-center login-content bg-zinc-900 bg-cover bg-center scale-[1.5]"
          style={{
            backgroundImage: `url('https://medianfiles.com/wp-content/uploads/2024/01/image00001-1.jpeg')`,
          }}
        ></section>
      </div>
    </>
  );
};

export default Login;
