import React from "react";
import "../style/style.css";

const TermsPage = () => {
  return (
    <>
      <main class="pt-8 pb-16 lg:pt-16 lg:pb-24 bg-white antialiased">
        <div class="flex justify-between px-4 mx-auto max-w-screen-xl ">
          <article class="mx-auto w-full max-w-2xl format format-sm sm:format-base lg:format-lg format-blue">
            <header class="pt-12 mb-4 lg:mb-6 not-format">
              <h1 class="mt-12 mb-4 text-3xl font-extrabold leading-tight text-gray-900 lg:mb-6 lg:text-4xl">
                Opći uvjeti poslovanja
              </h1>
            </header>

            <h2 className="font-semibold py-4">
              Vlasništvo nad internetskom stranicom
            </h2>
            <p>
              Sva prava na internetskoj stranici www.tramontothelabel.com
              pripadaju trgovačkom društvu Tramonto the Label d.o.o.
            </p>

            <h2 className="font-semibold py-4">Podaci o trgovačkom društvu</h2>
            <p>
              <ul>
                <li>
                  IBAN žiro računa u Zagrebačkoj banci d.d.:
                  HR7923600001102924256
                </li>
                <li>
                  Sjedište: Tramonto the label d.o.o., Črna voda 2, 10040 Zagreb
                </li>
                <li>Elektronička pošta: tramonto.label@gmail.com</li>
                <li>OIB: 29511597908</li>
                <li>MB: 5411599</li>
                <li>Temeljni kapital: 20.000,00 HRK</li>
                <li>Članovi uprave: Tea Kravaršćan</li>
                <li>Registracija: Trgovački sud u Zagrebu</li>
              </ul>
            </p>

            <h2 className="font-semibold py-4">Korištenje usluge</h2>

            <p>
              Korisnici se mogu registrirati na www.tramontothelabel.com unosom
              elektroničke adrese i lozinke za kasnije identificiranje i pristup
              uslugama. Usluga je namijenjena isključivo za osobnu upotrebu u
              skladu s uvjetima korištenja, zabranjuje se:
              <ul>
                <li>- Komercijalna ili javna upotreba usluge.</li>
                <li>- Nezakonita ili neprikladna upotreba.</li>
                <li>
                  - Kopiranje, distribucija ili izmjena sadržaja bez odobrenja.
                </li>
                <li>
                  - Manipulacija sigurnosnim mjerama ili softverom usluge.
                </li>
                <li>
                  - Korištenje usluge u svrhe suprotne Zakonu o autorskim
                  pravima, drugim zakonima ili uvjetima korištenja.
                </li>
              </ul>
              Nepoštivanje ovih pravila smatra se bitnim kršenjem uvjeta
              korištenja, što može rezultirati raskidom usluge bez prethodne
              najave.
            </p>

            <h2 className="font-semibold py-4">Premium članarina</h2>

            <p>
              Za pristup Premium sadržaju, korisnici moraju aktivirati plaćenu
              članarinu. Dostupni paketi članarine su dizajnirani da odgovaraju
              različitim potrebama i trajanjima, omogućujući korisnicima da
              odaberu ono što najbolje odgovara njihovim preferencijama. Detalji
              o trajanju svakog paketa su sljedeći:
              <ul>
                <li>- Mjesečni paket traje 30 dana od dana aktivacije.</li>
                <li>- Tromjesečni paket traje 90 dana</li>
                <li>- Polugodišnji paket traje 180 dana</li>
                <li>- Godišnji paket traje 365 dana</li>
              </ul>
              Članarina se automatski obnavlja na kraju odabranog razdoblja,
              osim ako korisnik ne odluči drugačije unutar postavki svog profila
              ili ako aktivno ne raskine članarinu. Važno je napomenuti da je
              raskid članarine moguć u bilo kojem trenutku, no povrat sredstava
              za već započeto razdoblje članarine nije moguć. Korisnicima se
              savjetuje da pažljivo razmisle o svojim opcijama i da se upoznaju
              s uvjetima korištenja prije aktiviranja članarine.
            </p>

            <h2 className="font-semibold py-4">Cijene i uvjeti plaćanja</h2>

            <p>
              Sve cijene koje su navedene na stranici i uključuju PDV. Cijene se
              mogu mijenjati s obavijesti pretplatnicima najmanje sedam dana
              unaprijed. Plaćanje se obavlja unaprijed putem navedenih kreditnih
              ili debitnih kartica. Neplaćanje rezultira ukidanjem pristupa.
            </p>

            <h2 className="font-semibold py-4">Akcije i popusti</h2>

            <p>Moguće su promocije i akcije s posebnim uvjetima.</p>

            <h2 className="font-semibold py-4">Načini plaćanja</h2>

            <p>
              Na našoj stranici nudimo različite načine plaćanja kako bismo
              osigurali da svaki korisnik može lako i sigurno izvršiti
              transakcije, bilo da se radi o plaćanju članarine ili jednokratnim
              kupnjama određenih programa. plaćanje putem web-stranice
              teakravrscan.com koristeći jednu od sljedećih kartica: Maestro,
              MasterCard, Visa, Visa Inspire, Diners, American Express ili
              Discover.
            </p>

            <h2 className="font-semibold py-4">
              Tehnička i korisnička podrška
            </h2>

            <p>
              Za podršku kontaktirajte nas putem e-maila
              tramonto.label@gmail.com. U slučaju nejasnoća, prevladavaju uvjeti
              navedeni ovdje.
            </p>

            <h2 className="font-semibold py-4">Odustanak od ugovora</h2>

            <p>
              Kupac je suglasan da nema pravo na odustanak od ugovora. Suglasno
              Zakonu o zaštiti potrošača, Kupac nema pravo na odustanak od
              ugovora, u slučaju: pružanja usluga, nakon što je usluga u
              potpunosti izvršena, ako je pružanje usluge počelo nakon izričite
              prethodne suglasnosti potrošača i uz njegovu potvrdu da zna da
              gubi pravo na odustanak od ugovora kada trgovac u potpunosti
              izvrši ugovor; isporuke robe proizvedene prema posebnim zahtjevima
              potrošača ili jasno personalizirane isporuke zapečaćenih audio,
              video zapisa ili računarskog softvera, koji su otpečaćeni nakon
              isporuke; isporuke digitalnog sadržaja koji nije isporučen na
              trajnom nosaču zapisa ako je izvršenje započelo poslje prethodne
              izričite suglasnosti potrošača i njegove potvrde da zna da na taj
              način gubi pravo na odustanak od ugovora.
            </p>

            <h2 className="font-semibold py-4">Odustanak od ugovora</h2>

            <p>
              Reklamacija kupca moguća je ako usluga nije ispunjena prema
              ugovorenoj, kao i zbog pogrešno obračunate cijene i drugih
              nedostataka Ukoliko Korisnik izjavi reklamaciju zbog toga što
              usluga nije ispunjena prema ugovorenoj, tada Korisnik može
              zahtjevati od Trgovca da izvrši ugovorenu uslugu. Ako je izvršenje
              usluge nemoguće ili protivpravno, Korisnik može zahtevati raskid
              ugovora. Reklamacija se može izjaviti putem e-maila na adresu:
              tramonto.label@gmail.com Dužni smo odgovoriti na reklamaciju u
              roku od 8 dana od dana prijema iste.
            </p>

            <h2 className="font-semibold py-4">Pravila o povratu sredstava</h2>

            <p>
              Korisnici nemaju pravo na povrat sredstava za programe koji su već
              kupljeni kao ni za aktivno tekuće članarine čiji je period
              korištenja već započeo. Ova ograničenja postavljena su kako bi se
              osiguralo pravedno korištenje resursa i sadržaja koji su
              korisnicima na raspolaganju. Zahtjevi za povrat sredstava moraju
              biti podneseni u skladu s našim postupcima, koji su detaljno
              opisani u uvjetima korištenja i politici povrata. Korisnici
              trebaju pružiti sve relevantne informacije i dokumentaciju koja
              podupire njihov zahtjev, kako bi proces bio što brži i efikasniji.
              Svaka situacija koja može rezultirati potrebom za povratom
              sredstava ocjenjuje se pojedinačno, uzimajući u obzir sve
              relevantne okolnosti. Trgovac zadržava pravo odobriti povrat
              sredstava u iznimnim slučajevima i prema vlastitom nahođenju,
              posebno u slučajevima valjanih reklamacija koje se smatraju
              opravdanim nakon detaljne provjere. Ako je originalno plaćanje
              izvršeno putem bankovne kartice, povrat sredstava vršit ćemo
              isključivo kroz iste kanale plaćanja, koristeći Maestro,
              MasterCard, Visa, Visa Inspire, Diners, American Express, ili
              Discover kartice. Ova praksa osigurava da se sredstva sigurno i
              efikasno vraćaju na račun s kojeg su originalno bila uplaćena.
            </p>

            <h2 className="font-semibold py-4">Odgovornost</h2>

            <p>
              Korisnik pristupa i koristi naše programe svjesno i na vlastitu
              odgovornost. Dužnost je korisnika da realno ocijeni svoje fizičke
              i psihičke kapacitete prije angažmana u bilo kojim aktivnostima
              predloženim kroz naše programe. Važno je da korisnik bude svjestan
              potencijalnih rizika i da prilagodi aktivnosti svojim
              sposobnostima kako bi se izbjegle ozljede. Korisnik prihvaća punu
              odgovornost za bilo kakve posljedice, uključujući ozljede, koje
              mogu proizaći tijekom korištenja naših programa. S druge strane,
              naša odgovornost je ograničena na štetu koja direktno proizlazi iz
              naše namjerne greške ili grube nepažnje u vezi s pružanjem usluga
              ili sadržaja na našoj internet stranici ili kroz naše programe.
              Naša je obveza osigurati da su usluge i sadržaj koje pružamo u
              skladu s općeprihvaćenim standardima i praksama. Međutim,
              odgovorni smo isključivo za štete koje su posljedica dokazane
              namjerne greške ili značajne nepažnje s naše strane.
            </p>

            <h2 className="font-semibold py-4">
              Izjava o sigurnosti online plaćanja
            </h2>

            <p>
              Pri plaćanju na našoj web trgovini koristite CorvusPay – napredni
              sustav za siguran prihvat platnih kartica putem interneta.
              CorvusPay osigurava potpunu tajnost Vaših kartičnih podataka već
              od trenutka kada ih upišete u CorvusPay platni formular. Platni
              podaci prosljeđuju se šifrirano od Vašeg web preglednika do banke
              koja je izdala Vašu karticu. Naša trgovina nikada ne dolazi u
              kontakt s cjelovitim podacima o Vašoj platnoj kartici. Također,
              podaci su nedostupni čak i djelatnicima CorvusPay sustava.
              Izolirana jezgra samostalno prenosi i upravlja osjetljivim
              podacima, čuvajući ih pri tom potpuno sigurnima. Formular za upis
              platnih podataka osiguran je SSL transportnom šifrom najveće
              pouzdanosti. Svi skladišteni podaci dodatno su zaštićeni
              šifriranjem, korištenjem kriptografskog uređaja certificiranog
              prema FIPS 140-2 Level 3 standardu. CorvusPay zadovoljava sve
              zahtjeve vezane uz sigurnost on-line plaćanja propisane od strane
              vodećih kartičnih brandova, odnosno posluje sukladno normi – PCI
              DSS Level 1 – najviši sigurnosni standard industrije platnih
              kartica. Pri plaćanju karticama uvrštenim u 3-D Secure program
              Vaša banka uz valjanost same kartice dodatno potvrđuje i Vaš
              identitet pomoću tokena ili lozinke. Corvus Info sve prikupljene
              informacije smatra bankovnom tajnom i tretira ih u skladu s tim.
              Informacije se koriste isključivo u svrhe za koje su namijenjene.
              Vaši osjetljivi podaci u potpunosti su sigurni, a njihova
              privatnost zajamčena je najmodernijim zaštitnim mehanizmima.
              Prikupljaju se samo podaci nužni za obavljanje posla sukladno
              propisanim zahtjevnim procedurama za on-line plaćanje. Sigurnosne
              kontrole i operativne procedure primijenjene na našu
              infrastrukturu osiguravaju trenutnu pouzdanost CorvusPay sustava.
              Uz to održavanjem stroge kontrole pristupa, redovitim praćenjem
              sigurnosti i dubinskim provjerama za sprječavanje ranjivosti mreže
              te planskim provođenjem odredbi o informacijskoj sigurnosti trajno
              održavaju i unaprjeđuju stupanj sigurnosti sustava zaštitom Vaših
              kartičnih podataka.
            </p>
          </article>
        </div>
      </main>
    </>
  );
};

export default TermsPage;
