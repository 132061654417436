import React, { useState, useEffect } from "react";

const TabelThree = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/tablethree`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((result) => {
        if (result.status === "success") {
          setData(result.data);
        } else {
          setError("Failed to fetch data");
        }
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, []);

  if (loading) return <p className="text-white">Loading...</p>;
  if (error) return <p className="text-white">Error: {error}</p>;

  return (
    <div className="px-4 mx-auto max-w-screen-xl lg:px-6 flex flex-col gap-8">
      <div className="flex gap-8 text-white py-4">
        <a className="py-2 px-4 rounded-lg bg-zinc-800" href="/app/tabelone">
          Tablica 1
        </a>
        <a className="py-2 px-4 rounded-lg bg-zinc-800" href="/app/tabeltwo">
          Tablica 2
        </a>
        <a
          className="py-2 px-4 rounded-lg bg-zinc-700 border border-zinc-200"
          href="/app/tabelthree"
        >
          Tablica 3
        </a>
        <a className="py-2 px-4 rounded-lg bg-zinc-800" href="/app/tabelfour">
          Tablica 4
        </a>
      </div>
      <h2 className="text-2xl font-bold text-white mb-4">Tablica 3</h2>

      <div>
        <h3 className="text-xl font-semibold text-white mb-2">Challenges</h3>
        <table className="w-full min-w-full bg-zinc-800 text-white border border-zinc-700">
          <thead>
            <tr className="text-left">
              <th className="py-2 px-4 border-b border-zinc-700">Title</th>
              <th className="py-2 px-4 border-b border-zinc-700">Price</th>
              <th className="py-2 px-4 border-b border-zinc-700">
                Order Count
              </th>
              <th className="py-2 px-4 border-b border-zinc-700">
                Privileged Users
              </th>
              <th className="py-2 px-4 border-b border-zinc-700">
                Privileged Price
              </th>
              <th className="py-2 px-4 border-b border-zinc-700">
                Total Revenue
              </th>
              <th className="py-2 px-4 border-b border-zinc-700">
                Privileged Revenue
              </th>
              <th className="py-2 px-4 border-b border-zinc-700">
                Non-privileged Revenue
              </th>
            </tr>
          </thead>
          <tbody>
            {data.challenges
              .filter((challenge) => challenge.order_count > 0)
              .map((challenge) => (
                <tr key={challenge.id} className="border-t border-zinc-700">
                  <td className="py-2 px-4 whitespace-nowrap">
                    {challenge.title}
                  </td>
                  <td className="py-2 px-4">{challenge.price}</td>
                  <td className="py-2 px-4">{challenge.order_count}</td>
                  <td className="py-2 px-4">
                    {challenge.privileged_users_count}
                  </td>
                  <td className="py-2 px-4">
                    {challenge.max_privileged_price || "N/A"}
                  </td>
                  <td className="py-2 px-4">
                    {challenge.total_revenue || "N/A"}
                  </td>
                  <td className="py-2 px-4">{challenge.privileged_revenue}</td>
                  <td className="py-2 px-4">
                    {challenge.non_privileged_revenue}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <div>
        <h3 className="text-xl font-semibold text-white mb-2">Ebooks</h3>
        <table className="w-full min-w-full bg-zinc-800 text-white border border-zinc-700">
          <thead>
            <tr className="text-left">
              <th className="py-2 px-4 border-b border-zinc-700">Title</th>
              <th className="py-2 px-4 border-b border-zinc-700">Price</th>
              <th className="py-2 px-4 border-b border-zinc-700">
                Order Count
              </th>
              <th className="py-2 px-4 border-b border-zinc-700">
                Privileged Users
              </th>
              <th className="py-2 px-4 border-b border-zinc-700">
                Privileged Price
              </th>
              <th className="py-2 px-4 border-b border-zinc-700">
                Total Revenue
              </th>
              <th className="py-2 px-4 border-b border-zinc-700">
                Privileged Revenue
              </th>
              <th className="py-2 px-4 border-b border-zinc-700">
                Non-privileged Revenue
              </th>
            </tr>
          </thead>
          <tbody>
            {data.ebooks
              .filter((ebook) => ebook.order_count > 0)
              .map((ebook) => (
                <tr key={ebook.id} className="border-t border-zinc-700">
                  <td className="py-2 px-4 whitespace-nowrap">{ebook.title}</td>
                  <td className="py-2 px-4">{ebook.price}</td>
                  <td className="py-2 px-4">{ebook.order_count}</td>
                  <td className="py-2 px-4">{ebook.privileged_users_count}</td>
                  <td className="py-2 px-4">
                    {ebook.max_privileged_price || "N/A"}
                  </td>
                  <td className="py-2 px-4">{ebook.total_revenue || "N/A"}</td>
                  <td className="py-2 px-4">{ebook.privileged_revenue}</td>
                  <td className="py-2 px-4">{ebook.non_privileged_revenue}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <div>
        <h3 className="text-xl font-semibold text-white mb-2">Trainings</h3>
        <table className="w-full min-w-full bg-zinc-800 text-white border border-zinc-700">
          <thead>
            <tr className="text-left">
              <th className="py-2 px-4 border-b border-zinc-700">Title</th>
              <th className="py-2 px-4 border-b border-zinc-700">Price</th>
              <th className="py-2 px-4 border-b border-zinc-700">
                Order Count
              </th>
              <th className="py-2 px-4 border-b border-zinc-700">
                Privileged Users
              </th>
              <th className="py-2 px-4 border-b border-zinc-700">
                Privileged Price
              </th>
              <th className="py-2 px-4 border-b border-zinc-700">
                Total Revenue
              </th>
              <th className="py-2 px-4 border-b border-zinc-700">
                Privileged Revenue
              </th>
              <th className="py-2 px-4 border-b border-zinc-700">
                Non-privileged Revenue
              </th>
            </tr>
          </thead>
          <tbody>
            {data.trainings
              .filter((training) => training.order_count > 0)
              .map((training) => (
                <tr key={training.id} className="border-t border-zinc-700">
                  <td className="py-2 px-4 whitespace-nowrap">
                    {training.title}
                  </td>
                  <td className="py-2 px-4">{training.price}</td>
                  <td className="py-2 px-4">{training.order_count}</td>
                  <td className="py-2 px-4">
                    {training.privileged_users_count}
                  </td>
                  <td className="py-2 px-4">
                    {training.max_privileged_price || "N/A"}
                  </td>
                  <td className="py-2 px-4">
                    {training.total_revenue || "N/A"}
                  </td>
                  <td className="py-2 px-4">{training.privileged_revenue}</td>
                  <td className="py-2 px-4">
                    {training.non_privileged_revenue}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TabelThree;
