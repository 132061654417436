import React, { useState, useEffect } from "react";
import fetchUser from "../../api/fetchUser";
import fetchSubscriptionmetabyuser from "../../api/fetchSubscriptionmetabyuser";

const BillingAlert = ({ user }) => {
  const [userData, setUserData] = useState(null);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (user) {
          const responseUser = await fetchUser.get(`/${user.id}`);
          setUserData(responseUser.data.data.user);
          const responseSubscription = await fetchSubscriptionmetabyuser.get(
            `/${user.id}`
          );
          if (responseSubscription.data.status === "success") {
            const subscription = responseSubscription.data.data.subscription;
            setSubscriptionData(subscription);

            // Izračunaj današnji datum i next_payment datum
            const today = new Date();
            const nextPaymentDate = new Date(subscription.next_payment);
            const timeDiff = nextPaymentDate - today;
            const daysDiff = timeDiff / (1000 * 60 * 60 * 24);

            // Proveri da li je unutar 5 dana
            if (daysDiff <= 5 && daysDiff >= 0) {
              setShowAlert(true);
            }
          }
        }
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, [user]);

  return (
    <>
      {userData &&
        subscriptionData &&
        subscriptionData.subscription_status === "active" &&
        showAlert && (
          <div className="p-8 md:p-0">
            <div
              className="flex gap-4 items-center p-4 text-sm text-white rounded-lg bg-zinc-800 border border-zinc-700 mb-4"
              role="alert"
            >
              <svg
                className="flex-shrink-0 inline w-4 h-4 me-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
              </svg>
              <span className="sr-only">Info</span>
              <div className="inline">
                <span>Vaša pretplata za Premium članstvo uskoro ističe.</span>
                <div className="inline">
                  <span className="ml-1">
                    Automatska naplata će se izvršiti
                  </span>
                  {subscriptionData.subscription_status === "active" && (
                    <span className="font-medium ml-1">
                      {subscriptionData.next_payment
                        .split("-")
                        .reverse()
                        .join(".")}
                      .
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
    </>
  );
};

export default BillingAlert;
