import React, { useState, useEffect } from "react";

const TabelOne = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/tabelone`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((result) => {
        if (result.status === "success") {
          setData(result.data);
        } else {
          setError("Failed to fetch data");
        }
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="px-4 mx-auto max-w-screen-xl lg:px-6">
      <div className="flex gap-8 text-white py-4 mb-8">
        <a
          className="py-2 px-4 rounded-lg bg-zinc-700 border border-zinc-200"
          href="/app/tabelone"
        >
          Tablica 1
        </a>
        <a className="py-2 px-4 rounded-lg bg-zinc-800" href="/app/tabeltwo">
          Tablica 2
        </a>
        <a className="py-2 px-4 rounded-lg bg-zinc-800" href="/app/tabelthree">
          Tablica 3
        </a>
        <a className="py-2 px-4 rounded-lg bg-zinc-800" href="/app/tabelfour">
          Tablica 4
        </a>
      </div>
      <h2 className="text-2xl font-bold text-white mb-4">Tablica 1</h2>
      <table className="min-w-full w-full bg-zinc-800 text-white border border-zinc-700">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b border-zinc-700">Plan</th>
            <th className="py-2 px-4 border-b border-zinc-700">Active</th>
            <th className="py-2 px-4 border-b border-zinc-700">Nonactive</th>
            <th className="py-2 px-4 border-b border-zinc-700">Cancelled</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            Object.keys(data).map((plan) => (
              <tr key={plan} className="text-center border-t border-zinc-700">
                <td className="py-2 px-4">
                  {plan.charAt(0).toUpperCase() + plan.slice(1)}
                </td>
                <td className="py-2 px-4">{data[plan].active}</td>
                <td className="py-2 px-4">{data[plan].nonactive}</td>
                <td className="py-2 px-4">{data[plan].cancelled}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default TabelOne;
