// ChallengeList.jsx
import React, { useState, useEffect } from "react";
import "../../style/style.css";
import fetchEbooks from "../../api/fetchEbook";

const EbookList = () => {
  const [ebooks, setEbooks] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchEbooks.get(`/`);
        setEbooks(response.data.data.ebooks);
      } catch (err) {
        console.error("Error fetching ebooks:", err.message);
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      {ebooks.length > 0 ? (
        <div className="grid gap-8 grid-cols-2 lg:grid-cols-5">
          {ebooks.map((ebook) => {
            // Parse cover_images if it's a string
            const coverImages = ebook.cover_images
              ? JSON.parse(ebook.cover_images)
              : [];

            return (
              <div
                key={ebook.id}
                className="overflow-hidden transition cursor-pointer rounded-lg shadow bg-zinc-800 border border-zinc-700"
              >
                <a href={`/app/ebooks/${ebook.id}`}>
                  <img
                    alt={ebook.title}
                    src={
                      coverImages.length > 0
                        ? coverImages[0]
                        : "https://medianfiles.com/wp-content/uploads/2024/03/Snimka-zaslona-2024-03-22-053900.png"
                    }
                    className="aspect-[210/297] w-full object-cover"
                  />
                  <div className="p-4">
                    <h5 className="text-red-600 text-xl font-semibold tracking-tight text-white">
                      {ebook.title}
                    </h5>
                    <p className="hidden text-sm flex items-center">
                      <span className="text-white text-xs">
                        by {ebook.author}
                      </span>
                    </p>
                    {ebook.with_subscription === false ? (
                      <div className="flex items-center gap-1 mt-2">
                        <svg
                          className="w-4 h-4 text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 10V7a4 4 0 1 1 8 0v3h1a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-7c0-1.1.9-2 2-2h1Zm2-3a2 2 0 1 1 4 0v3h-4V7Zm2 6c.6 0 1 .4 1 1v3a1 1 0 1 1-2 0v-3c0-.6.4-1 1-1Z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <span className="text-white text-sm font-semibold">
                          €{ebook.price}
                        </span>
                      </div>
                    ) : (
                      <div className="flex items-center gap-1 mt-2">
                        <span className="text-white text-sm font-semibold">
                          Uključeno u Premium
                        </span>
                      </div>
                    )}
                  </div>
                </a>
              </div>
            );
          })}
        </div>
      ) : (
        <p>No ebooks available.</p>
      )}
    </div>
  );
};

export default EbookList;
