import React, { useState } from "react";

const TabelTwo = () => {
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("2024-01-01");
  const [endDate, setEndDate] = useState("2024-06-30");

  const fetchProducts = () => {
    setLoading(true);
    setError(null);
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/tabeltwo?start_date=${startDate}&end_date=${endDate}`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((result) => {
        if (result.status === "success") {
          setProducts(result.data);
        } else {
          setError("Failed to fetch data");
        }
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  };

  return (
    <div className="px-4 mx-auto max-w-screen-xl lg:px-6">
      <div className="flex gap-8 text-white py-4 mb-8">
        <a className="py-2 px-4 rounded-lg bg-zinc-800" href="/app/tabelone">
          Tablica 1
        </a>
        <a
          className="py-2 px-4 rounded-lg bg-zinc-700 border border-zinc-200"
          href="/app/tabeltwo"
        >
          Tablica 2
        </a>
        <a className="py-2 px-4 rounded-lg bg-zinc-800" href="/app/tabelthree">
          Tablica 3
        </a>
        <a className="py-2 px-4 rounded-lg bg-zinc-800" href="/app/tabelfour">
          Tablica 4
        </a>
      </div>
      <h2 className="text-2xl font-bold text-white mb-4">Tablica 2</h2>
      <div className="mb-8">
        <label className="text-white">Start Date: </label>
        <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          className="bg-zinc-700 text-white p-2 rounded"
        />
        <label className="text-white ml-4">End Date: </label>
        <input
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          className="bg-zinc-700 text-white p-2 rounded"
        />
        <button
          onClick={fetchProducts}
          className="bg-white text-black font-bold py-2 px-4 rounded ml-4"
        >
          Dohvati podatke
        </button>
      </div>
      {loading && <p className="text-white">Loading..</p>}
      {error && <p className="text-white">Error: {error}</p>}
      <table className="min-w-full w-full bg-zinc-800 text-white border border-zinc-700">
        <thead>
          <tr className="text-left">
            <th className="py-2 px-4 border-b border-zinc-700">Title</th>
            <th className="py-2 px-4 border-b border-zinc-700">Order Count</th>
            <th className="py-2 px-4 border-b border-zinc-700">
              Total Revenue
            </th>
          </tr>
        </thead>
        <tbody>
          {products.map((product, index) => (
            <tr key={index} className="border-t border-zinc-700">
              <td className="py-2 px-4">{product.title}</td>
              <td className="py-2 px-4">{product.order_count}</td>
              <td className="py-2 px-4">{product.total_revenue}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TabelTwo;
