import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  useParams,
  useNavigate,
} from "react-router-dom";
import fetchOrder from "../../api/fetchOrder";

const OrderConfirmation = () => {
  // Dohvati parametre iz URL-a
  const navigate = useNavigate();
  const { order_number } = useParams();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseOrder = await fetchOrder.get(`/${order_number}`);
        const orderData = responseOrder.data.data.order;
        const product_category = orderData.product_category;
        const product_id = orderData.product_id;

        let redirectUrl; // Promenljiva deklarisana van if-else bloka

        if (product_category === "subscription") {
          redirectUrl = `/app`;
        } else {
          redirectUrl = `/app/${product_category}s/${product_id}`;
        }

        // Preusmeri korisnika nakon 3 sekunde
        setTimeout(() => {
          navigate(redirectUrl);
        }, 3000);
      } catch (error) {
        console.error(
          "Greška prilikom dohvatanja podataka o porudžbini:",
          error
        );
      }
    };

    fetchData();
  }, [order_number]);

  return (
    <>
      <section>
        <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12">
          <a
            href="#"
            className="mt-12 inline-flex justify-between items-center text-sm text-white rounded-full"
          >
            <svg
              className="w-20 h-20 text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m7 10 2 2 4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
          </a>
          <h1 className="mt-12 mb-4 text-4xl font-bold tracking-tight leading-none text-white md:text-5xl lg:text-6xl">
            Hvala na kupnji!
          </h1>
          <p className="mb-8 text-lg font-normal text-white lg:text-xl sm:px-16 xl:px-48">
            Samo trenutak, uskoro ćemo napraviti redirekciju
          </p>
          <div className="hidden flex flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
            <a
              href="#"
              className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300"
            >
              Learn more
              <svg
                className="ml-2 -mr-1 w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default OrderConfirmation;
