// ChallengeList.jsx
import React, { useState, useEffect } from "react";
import "../../style/style.css";
import fetchTrening from "../../api/fetchTrening";

const TreningList = () => {
  const [trainings, setTrainings] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchTrening.get(`/`); // Pretpostavljamo da ova funkcija dohvaća sve izazove
        setTrainings(response.data.data.trainings);
      } catch (err) {
        console.error("Error fetching trainings:", err);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      {trainings.length > 0 ? (
        <div className="grid gap-8 lg:grid-cols-3">
          {trainings
            .sort((a, b) => a.id - b.id)
            .map((trening) => (
              // Move the key prop to the <a> tag
              <a key={trening.id} href={`/app/trainings/${trening.id}`}>
                <div
                  className="h-80 max-w-sm rounded-lg shadow bg-cover bg-center flex"
                  style={{ backgroundImage: `url(${trening.cover_image})` }}
                >
                  <div className="relative flex bg-black bg-opacity-30 hover:bg-opacity-40 rounded-lg flex-start items-end w-full h-full z-10">
                    <div className="p-5 w-full">
                      <p className="font-semibold uppercase text-white text-center pb-2  mx-auto">
                        GYM
                      </p>
                      <h3 className="text-anton uppercase text-white text-center pb-12 text-5xl mx-auto">
                        {trening.title}
                      </h3>
                      <span className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded bg-red-800  text-red-100">
                        Novo
                      </span>
                      <h5 className="text-crimson text-2xl font-semibold tracking-tight text-white mt-2 flex items-center gap-2">
                        8 tjedana
                      </h5>
                      <span className="text-white text-sm flex items-center gap-1 font-semibold mt-1">
                        <svg
                          className="w-4 h-4 text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 10V7a4 4 0 1 1 8 0v3h1a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-7c0-1.1.9-2 2-2h1Zm2-3a2 2 0 1 1 4 0v3h-4V7Zm2 6c.6 0 1 .4 1 1v3a1 1 0 1 1-2 0v-3c0-.6.4-1 1-1Z"
                            clipRule="evenodd"
                          />
                        </svg>
                        €{trening.price}
                      </span>
                      <span className="text-white flex items-center gap-1 text-base">
                        Cijena za Premium članove:{" "}
                        <span className="font-semibold">€24.00</span>
                      </span>
                    </div>
                  </div>
                </div>
              </a>
            ))}

          <div className="hidden h-80 max-w-sm rounded-lg shadow bg-cover bg-center flex border border-zinc-200 border-dashed">
            <div className="relative flex rounded-lg flex-start items-end w-full h-full z-10">
              <div className="p-5">
                <span className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded bg-zinc-800 text-red-100 text-5xl">
                  Trening
                </span>
                <h5 className="text-crimson mb-1 text-2xl font-semibold tracking-tight text-white mt-2">
                  Uskoro dolazi
                </h5>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p>No challenges available.</p>
      )}
    </div>
  );
};

export default TreningList;
