// Login.js
import React from "react";
import "../../style/style.css";

import RegistrationBox from "./RegistrationBox"; // Import the new component

const Registration = () => {
  return (
    <>
      <RegistrationBox />
      <div className="w-screen h-screen overflow-x-hidden overflow-y-hidden">
        <section
          className="w-full h-full flex items-center justify-center login-content bg-zinc-900 bg-cover bg-center scale-[1.5]"
          style={{
            backgroundImage: `url('https://medianfiles.com/wp-content/uploads/2024/01/image00001-1.jpeg')`,
          }}
        ></section>
      </div>
    </>
  );
};

export default Registration;
