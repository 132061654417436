// ChallengeList.jsx
import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../style/style.css";
import { Link } from "react-router-dom";
import fetchChallenges from "../../api/fetchChallenge"; // Pretpostavljamo da imate funkciju za dohvaćanje svih izazova

const ChallengeList = ({ user }) => {
  const [bootyPurchaseData, setBootyPurchaseData] = useState(null);
  const [challenges, setChallenges] = useState([]);

  useEffect(() => {
    if (!user || !user.id) return; // Proverite da li user ili user.id postoje

    const fetchData = async () => {
      try {
        const purchasedResponse = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/user/${user.id}/purchased?product_id=15&product_category=challenge`
        );
        if (purchasedResponse.data.status === "success") {
          setBootyPurchaseData(purchasedResponse.data);
        }
      } catch (err) {
        console.error("Error fetching purchased data:", err);
      }
    };

    fetchData();
  }, [user]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchChallenges.get(`/`);
        // Proverite strukturu odgovora
        if (
          response.data &&
          response.data.data &&
          response.data.data.challenges
        ) {
          setChallenges(response.data.data.challenges);
        } else {
          console.error("Unexpected response structure:", response.data);
        }
      } catch (err) {
        console.error("Error fetching challenges:", err);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      {challenges.length > 0 ? (
        <div className="grid gap-8 lg:grid-cols-4">
          {challenges
            .filter((challenge) => challenge && challenge.id) // Filterišite nedefinisane objekte ili objekte bez id-a
            .sort((a, b) => a.id - b.id)
            .map((challenge) => (
              <a key={challenge.id} href={`/app/challenges/${challenge.id}`}>
                <div
                  className="h-80 rounded-lg shadow bg-cover bg-center flex"
                  style={{ backgroundImage: `url(${challenge.image_url})` }}
                >
                  <div className="relative flex flex-col items-center justify-center bg-black bg-opacity-30 hover:bg-opacity-40 rounded-lg flex-start items-end w-full h-full z-10">
                    {challenge.id === 15 && (
                      <div className="flex gap-2 pb-2">
                        <span className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded bg-red-800 text-red-100">
                          Novo
                        </span>
                      </div>
                    )}
                    <h3 className="text-anton uppercase text-white text-center text-4xl mx-auto">
                      {challenge.title}
                    </h3>
                    <span className="text-sm text-white text-center block mt-2">
                      {challenge.video_count} treninga
                    </span>
                  </div>
                </div>
              </a>
            ))}
        </div>
      ) : (
        <p>No challenge available.</p>
      )}
    </div>
  );
};

export default ChallengeList;
