import React, { useState, useEffect } from "react";

const TabelFour = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/tabelfour`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((result) => {
        if (result.status === "success") {
          setSubscriptions(result.data.subscriptions);
        } else {
          setError("Failed to fetch data");
        }
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, []);

  if (loading) return <p className="text-white">Loading...</p>;
  if (error) return <p className="text-white">Error: {error}</p>;

  return (
    <div className="px-4 mx-auto max-w-screen-xl lg:px-6">
      <div className="flex gap-8 text-white py-4 mb-8">
        <a className="py-2 px-4 rounded-lg bg-zinc-800" href="/app/tabelone">
          Tablica 1
        </a>
        <a className="py-2 px-4 rounded-lg bg-zinc-800" href="/app/tabeltwo">
          Tablica 2
        </a>
        <a className="py-2 px-4 rounded-lg bg-zinc-800" href="/app/tabelthree">
          Tablica 3
        </a>
        <a
          className="py-2 px-4 rounded-lg bg-zinc-700 border border-zinc-200"
          href="/app/tabelfour"
        >
          Tablica 4
        </a>
      </div>
      <h2 className="text-2xl font-bold text-white mb-4">Tablica 4</h2>
      <table className="min-w-full bg-zinc-800 text-white border border-zinc-700">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b border-zinc-700">First Name</th>
            <th className="py-2 px-4 border-b border-zinc-700">Last Name</th>
            <th className="py-2 px-4 border-b border-zinc-700">Email</th>
            <th className="py-2 px-4 border-b border-zinc-700">
              Subscription Type
            </th>
            <th className="py-2 px-4 border-b border-zinc-700">Start Date</th>
            <th className="py-2 px-4 border-b border-zinc-700">Next Payment</th>
            <th className="py-2 px-4 border-b border-zinc-700">Status</th>
            <th className="py-2 px-4 border-b border-zinc-700">Date Paid</th>
            <th className="py-2 px-4 border-b border-zinc-700">
              Latest Order Total
            </th>
            <th className="py-2 px-4 border-b border-zinc-700">
              Customer's Value
            </th>
          </tr>
        </thead>
        <tbody>
          {subscriptions.map((sub, index) => (
            <tr key={index} className="border-t border-zinc-700">
              <td className="py-2 px-4">{sub.first_name}</td>
              <td className="py-2 px-4">{sub.last_name}</td>
              <td className="py-2 px-4">{sub.email}</td>
              <td className="py-2 px-4">{sub.subscription_type}</td>
              <td className="py-2 px-4">
                {new Date(sub.start_date).toLocaleDateString()}
              </td>
              <td className="py-2 px-4">
                {new Date(sub.next_payment).toLocaleDateString()}
              </td>
              <td className="py-2 px-4">{sub.subscription_status}</td>
              <td className="py-2 px-4">
                {new Date(sub.date_paid).toLocaleString()}
              </td>
              <td className="py-2 px-4">{sub.latest_order_total}</td>
              <td className="py-2 px-4">{sub.customers_value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TabelFour;
