import React, { useState, useEffect } from "react";
import { Avatar, Dropdown, Navbar } from "flowbite-react";
import fetchUser from "../api/fetchUser";
import fetchSubscriptionmetabyuser from "../api/fetchSubscriptionmetabyuser";

import SubscriptionCancelModal from "./Subscription/SubscriptionCancelModal";

const handleLogout = () => {
  // Clear access token from localStorage and reset isLoggedIn state
  localStorage.removeItem("accessToken");
  window.location.href = "/";
};

const Header = ({ user }) => {
  const [userData, setUserData] = useState(null);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [openCancelModal, setOpenCancelModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (user) {
          const responseUser = await fetchUser.get(`/${user.id}`);
          setUserData(responseUser.data.data.user);
          const responseSubscription = await fetchSubscriptionmetabyuser.get(
            `/${user.id}`
          );
          if (responseSubscription.data.status === "success") {
            setSubscriptionData(responseSubscription.data.data.subscription);
          }
        }
      } catch (err) {}
    };
    fetchData();
  }, [user]);

  const handleContinueSubscription = async () => {
    console.log(subscriptionData);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/subscriptionmeta/${subscriptionData.id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            subscription_status: "active",
          }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        window.location.reload();
      } else {
        console.error("Error while cancelling subscription:", data.message);
        // Obradite grešku u UI-u
      }
    } catch (error) {
      console.error("Network error:", error);
      // Obradite grešku u vezi s mrežom
    }
  };

  return (
    <>
      {userData ? (
        <header className="antialiased" id="header">
          <nav className=" px-4 lg:px-6 py-2.5">
            <div className="flex flex-wrap justify-between items-center">
              <div className="flex justify-start items-center">
                <a href="/app" className="flex mr-4">
                  <img
                    src="https://medianfiles.com/wp-content/uploads/2024/01/trasparent.png"
                    className="mr-3 h-8"
                    alt="FlowBite Logo"
                  />
                </a>
              </div>
              <div
                className="hidden w-full md:block md:w-auto"
                id="navbar-default"
              >
                <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 md:flex-row md:space-x-8 md:mt-0">
                  <li>
                    <a
                      href="/app"
                      className="block py-2 pl-3 pr-4 text-white"
                      aria-current="page"
                    >
                      Naslovna
                    </a>
                  </li>
                  <li>
                    <a
                      href="/app/trainings"
                      className="block py-2 pl-3 pr-4 text-white"
                      aria-current="page"
                    >
                      Izazovi
                    </a>
                  </li>
                  <li>
                    <a
                      href="/app/challenges"
                      className="block py-2 pl-3 pr-4 text-white"
                      aria-current="page"
                    >
                      Treninzi
                    </a>
                  </li>
                  <li>
                    <a
                      href="/app/ebooks"
                      className="block py-2 pl-3 pr-4 text-white"
                    >
                      Ebookovi
                    </a>
                  </li>
                  {userData.role === "admin" && (
                    <li>
                      <a
                        href="/app/tabelone"
                        className="block py-2 pl-3 pr-4 text-white"
                      >
                        Analitika
                      </a>
                    </li>
                  )}
                </ul>
              </div>
              <div className="flex items-center lg:order-2">
                <Dropdown
                  className="z-50"
                  arrowIcon={false}
                  inline
                  label={
                    <Avatar
                      className={`rounded-full border-2 ${subscriptionData ? "border-amber-500" : ""}`}
                      alt="User settings"
                      img={userData.picture}
                      rounded
                    />
                  }
                >
                  <Dropdown.Header>
                    <div className="flex items-center justify-between mb-2">
                      <Avatar
                        alt="User settings"
                        img={userData.picture}
                        rounded
                      />
                    </div>
                    <p className="text-base font-semibold leading-none">
                      {userData.first_name} {userData.last_name}
                    </p>
                    <p className="text-sm font-normal">{userData.email}</p>
                    {subscriptionData ? (
                      <div className="flex flex-col mt-1">
                        <div>
                          <span className="uppercase text-xs inline-block rounded-md bg-amber-500 p-1 mt-1 font-bold text-white">
                            Premium član
                          </span>
                        </div>
                        {subscriptionData.subscription_status === "active" && (
                          <div className="flex flex-col text-xs inline-block mt-1">
                            Sljedeća naplata:
                            <span className="font-bold">
                              {subscriptionData.next_payment
                                .split("-")
                                .reverse()
                                .join(".")}
                            </span>
                          </div>
                        )}
                        {subscriptionData.subscription_status ===
                          "cancelled" && (
                          <div className="flex flex-col text-xs inline-block mt-1">
                            Tvoja pretplata je otkazana.
                          </div>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                  </Dropdown.Header>
                  {subscriptionData ? (
                    subscriptionData.subscription_status === "active" ? (
                      <>
                        <Dropdown.Item onClick={() => setOpenCancelModal(true)}>
                          Otkaži pretplatu
                        </Dropdown.Item>
                      </>
                    ) : subscriptionData.subscription_status === "cancelled" ? (
                      <Dropdown.Item onClick={handleContinueSubscription}>
                        Nastavi pretplatu
                      </Dropdown.Item>
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )}

                  <Dropdown.Divider />
                  <Dropdown.Item onClick={handleLogout}>
                    Odjavi se
                  </Dropdown.Item>
                </Dropdown>
                {subscriptionData && (
                  <SubscriptionCancelModal
                    user={user}
                    show={openCancelModal}
                    onClose={() => setOpenCancelModal(false)}
                    subscriptionId={subscriptionData.id}
                  />
                )}
              </div>
            </div>
          </nav>
        </header>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default Header;
