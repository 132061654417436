import React, { useState, useEffect } from "react";
import axios from "axios";
import "./../style/style.css";

import TreningList from "./Trainings/TrainingList";
import ChallengeList from "./Challenges/ChallengeList";
import EbookList from "./Ebooks/EbookList";
import SubscriptionBanner from "./Subscription/SubscriptionBanner";
import PartnerPromoCodes from "./Dashboard/PartnerPromoCodes";

import { Carousel } from "flowbite-react";

const IndexPage = () => {
  return (
    <>
      <nav className="border-zinc-200 z-50 w-full scrolled">
        <div className="flex flex-wrap items-center justify-between mx-2 lg:mx-12 p-2">
          <a href="/" className="flex items-center">
            <img
              src="https://medianfiles.com/wp-content/uploads/2024/01/trasparent.png"
              className="h-12 mr-3"
              alt="BetterYouGym Logo"
            />
          </a>
          <div className="flex flex-col items-center justify-center">
            <h1 className="text-3xl lg:text-4xl font-extrabold tracking-tight leading-none text-white md:text-xl lg:text-3xl text-crimson">
              Better You
            </h1>
            <p className="text-base font-semibold text-white lg:text-sm sm:px-16 xl:px-48 uppercase tracking-wide">
              Online Gym
            </p>
          </div>
          <div className="flex md:order-2">
            <a
              href="/app"
              className="inline-flex justify-center items-center py-2 px-5 text-base font-medium text-center text-color rounded-lg bg-white"
            >
              Prijavi se
            </a>
          </div>
        </div>
      </nav>
      <section className="relative w-full bg-cover bg-center">
        <div className="mx-auto w-screen max-w-screen-xl">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-8">
            <div className="flex flex-col md:flex-row gap-4">
              <div className="block relative">
                <img
                  className="w-[18rem]"
                  src="https://medianfiles.com/wp-content/uploads/2024/10/WhatsApp-Image-2024-10-27-at-18.29.41.jpeg"
                ></img>
                <span className="hidden text-center absolute top-4 left-0 right-0 text-white text-xs font-medium me-2 px-2.5 py-0.5 rounded">
                  Tea Kravaršćan
                </span>
              </div>
              <div
                className="p-0 md:px-8 w-full lg:w-96
            "
              >
                <div className="flex gap-2">
                  <span className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded bg-red-800  text-red-100">
                    Novo
                  </span>
                </div>
                <h1 className="text-anton uppercase text-white text-6xl z-50 mt-6">
                  BETTER YOU <span>CHALLENGE</span>
                </h1>
                <p className="text-base text-white  block mt-4">
                  12 unaprijed snimljenih treninga za cijelo tijelo u trajanju
                  od 30-50 minuta Instagram grupa podrške<br></br>
                  Kuharice i plan prehrane ( suficit, deficit, održavanje)
                  <br></br>
                  Popusti partnera<br></br>
                  Nagrade za najbolje transformacije
                </p>
                <p className="text-base text-white  block mt-4">
                  Start: 4.11.<br></br>
                  Pristup do 15.12.<br></br>
                  Potrebni rekviziti: Bučice/girje, guma
                </p>
                <span className="text-white flex items-center gap-1 text-lg font-semibold mt-6">
                  <svg
                    className="w-5 h-5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 10V7a4 4 0 1 1 8 0v3h1a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-7c0-1.1.9-2 2-2h1Zm2-3a2 2 0 1 1 4 0v3h-4V7Zm2 6c.6 0 1 .4 1 1v3a1 1 0 1 1-2 0v-3c0-.6.4-1 1-1Z"
                      clipRule="evenodd"
                    />
                  </svg>
                  €33.00
                </span>
                <span className="text-white flex items-center gap-1 text-base">
                  Cijena za članove:{" "}
                  <span className="font-semibold">€23.00</span>
                </span>
                <div className="hidden flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4 mt-6">
                  <a
                    href="/app/checkout/challenge/16"
                    className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center rounded-lg border focus:ring-4 focus:ring-amber-300 bg-white"
                  >
                    Kupi odmah
                  </a>
                </div>
              </div>
            </div>
            <div className="flex flex-col md:flex-row gap-4">
              <div className="block relative">
                <img
                  className="w-[18rem]"
                  src="https://medianfiles.com/wp-content/uploads/2024/03/WhatsApp-Image-2024-03-22-at-08.28.00.jpeg"
                ></img>
                <span className="hidden text-center absolute top-4 left-0 right-0 text-white text-xs font-medium me-2 px-2.5 py-0.5 rounded">
                  Tea Kravaršćan
                </span>
              </div>
              <div
                className="p-0 md:px-8 w-full lg:w-96
            "
              >
                <div className="flex gap-2">
                  <span className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded bg-red-800  text-red-100">
                    Popularno
                  </span>
                </div>
                <h1 className="text-anton uppercase text-white text-6xl z-50 mt-6">
                  Booty <span>Challenge</span>
                </h1>

                <p className="text-base text-white  block mt-4">
                  8-tjedni program treninga za donji dio tijela sa fokusom na
                  stražnjicu. GYM Program je namjenjen svima bilo da ste
                  početnik ili napredni vježbač. Uključuje 4 treninga tjedno s
                  demonstracijom vježbi te uključuje i vježbe za gornji dio
                  tijela i core.
                </p>
                <div className="flex gap-1">
                  <span className="text-white flex items-center gap-1 text-lg font-semibold mt-6">
                    <svg
                      className="w-5 h-5"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 10V7a4 4 0 1 1 8 0v3h1a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-7c0-1.1.9-2 2-2h1Zm2-3a2 2 0 1 1 4 0v3h-4V7Zm2 6c.6 0 1 .4 1 1v3a1 1 0 1 1-2 0v-3c0-.6.4-1 1-1Z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="line-through"> €40.00</span>
                  </span>
                  <span className="text-white flex items-center gap-1 text-lg font-semibold mt-6">
                    €28.00
                  </span>
                </div>

                <span className="text-white flex items-center gap-1 text-base">
                  Cijena za članove:{" "}
                  <div className="flex gap-1">
                    <span className="font-semibold line-through">€24.00</span>
                    <span className="font-semibold">€20.00</span>
                  </div>
                </span>
                <div className="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4 mt-6">
                  <a
                    href="/app/checkout/training/2"
                    className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center rounded-lg border focus:ring-4 focus:ring-amber-300 bg-white"
                  >
                    Kupi odmah
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="hidden absolute w-full h-96 bottom-0 bg-gradient-to-t from-zinc-900"></div>
        <div className="hidden absolute w-full h-96 top-0 bg-gradient-to-b from-zinc-900"></div>
      </section>

      <section className="hidden">
        <div className="py-8 px-4 mx-auto max-w-screen-xl lg:px-6 mt-16">
          <div className="grid gap-8 lg:grid-cols-3">
            <a href="/app/checkout/training/2">
              <div
                className="h-80 max-w-sm rounded-lg shadow bg-cover bg-center flex"
                style={{
                  backgroundImage:
                    "url('https://medianfiles.com/wp-content/uploads/2024/01/C4F3D019-56B4-4C8A-B020-F97118075841.webp')",
                }}
              >
                <div className="relative flex bg-black bg-opacity-30 hover:bg-opacity-40 rounded-lg flex-start items-end w-full h-full z-10">
                  <div className="p-5 w-full">
                    <p className="font-semibold uppercase text-white text-center pb-2  mx-auto">
                      GYM
                    </p>
                    <h3 className="text-anton uppercase text-white text-center pb-12 text-5xl mx-auto">
                      Booty Challenge
                    </h3>
                    <span className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded bg-red-800  text-red-100">
                      Novo
                    </span>
                    <h5 className="text-crimson text-2xl font-semibold tracking-tight text-white mt-2 flex items-center gap-2">
                      8 tjedana
                    </h5>
                    <span className="text-white text-sm flex items-center gap-1 font-semibold mt-1">
                      <svg
                        className="w-4 h-4 text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 10V7a4 4 0 1 1 8 0v3h1a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-7c0-1.1.9-2 2-2h1Zm2-3a2 2 0 1 1 4 0v3h-4V7Zm2 6c.6 0 1 .4 1 1v3a1 1 0 1 1-2 0v-3c0-.6.4-1 1-1Z"
                          clipRule="evenodd"
                        />
                      </svg>
                      €40.00
                    </span>
                    <span className="text-white flex items-center gap-1 text-base">
                      Cijena za Premium članove:{" "}
                      <span className="font-semibold">€24.00</span>
                    </span>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </section>

      <SubscriptionBanner />

      <section className="">
        <div className="px-4 mx-auto max-w-screen-xl lg:px-6">
          <h2 className="text-anton mt-12 mb-8 text-4xl md:text-4xl text-white">
            Treninzi
          </h2>
          <ChallengeList user="0" />
        </div>
      </section>

      <section className="">
        <div className="px-4 mx-auto max-w-screen-xl lg:px-6">
          <h2 className="text-anton mt-12 mb-8 text-4xl md:text-4xl text-white">
            Ebooks
          </h2>
          <EbookList />
        </div>
      </section>

      <section className="m-4">
        <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6 shadow">
          <div className="h-56 sm:h-64 xl:h-80 2xl:h-96">
            <Carousel slideInterval={2500}>
              <img
                src="https://medianfiles.com/wp-content/uploads/2024/03/WhatsApp-Image-2024-02-17-at-14.15.47.jpeg"
                alt="..."
              />
            </Carousel>
          </div>
        </div>
      </section>
      <footer className="rounded-lg shadow m-4 lg:m-12 bg-zinc-800" id="footer">
        <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
          <span className="text-sm sm:text-center text-zinc-400">
            © 2024
            <a href="https://flowbite.com/" className="hover:underline px-1">
              Better You Online Gym -
            </a>
            All Rights Reserved.
          </span>
          <ul className="flex flex-wrap items-center mt-3 text-sm font-medium text-zinc-400 sm:mt-0">
            <li>
              <a href="/terms" className="mr-4 hover:underline md:mr-6">
                Opći uvjeti korištenja
              </a>
            </li>
          </ul>
        </div>
      </footer>
    </>
  );
};

export default IndexPage;
