import React, { useState, useEffect } from "react";
import axios from "axios";
import fetchUser from "../../api/fetchUser";

const CommentsSection = ({ user, challenge_id }) => {
  // Changed training_id to challenge_id
  const [comments, setComments] = useState([]);
  const [commentText, setCommentText] = useState("");
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    fetchComments();
  }, []);

  const fetchComments = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/challengescomments/${challenge_id}` // Changed route
      );
      setComments(response.data.data.challenges); // Assuming the response structure is similar, changed `trainings` to `challenges`
    } catch (error) {
      console.error("error fetching comments:", error);
    }
  };

  const handleCommentChange = (e) => {
    setCommentText(e.target.value);
  };

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    if (!commentText.trim()) return;

    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate().toString().padStart(2, "0")}/${(currentDate.getMonth() + 1).toString().padStart(2, "0")}/${currentDate.getFullYear()}`;

    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/challengescomments/`,
        {
          // Changed route
          challenge_id: challenge_id, // Changed parameter name
          user_id: user.id,
          text: commentText,
          time: formattedDate, // Use the formatted date
        }
      );
      setCommentText("");
      fetchComments();
    } catch (error) {
      console.error("Error submitting comment:", error);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userDataResponse = await fetchUser.get(`/${user.id}`);
        setUserData(userDataResponse.data.data.user);
      } catch (err) {
        console.error(`Error fetching user data:`, err);
      }
    };
    fetchUserData();
  }, [user]);

  return (
    <section className="not-format mt-12">
      <form className="mb-6" onSubmit={handleCommentSubmit}>
        <label htmlFor="chat" className="sr-only">
          Tvoj komentar
        </label>
        <div className="flex items-center px-3 py-2 rounded-lg bg-zinc-700">
          {userData && (
            <img
              src={userData.picture}
              alt="User avatar"
              className="flex w-10 rounded-full overflow-hidden"
            />
          )}
          <textarea
            id="chat"
            rows="1"
            className="block mx-4 p-2.5 w-full text-sm rounded-lg border bg-zinc-800 border-zinc-600 placeholder-zinc-400 text-white focus:ring-blue-500 focus:border-blue-500"
            placeholder="Tvoj komentar..."
            value={commentText}
            onChange={handleCommentChange}
          ></textarea>
          <button
            type="submit"
            className="inline-flex justify-center p-2 text-blue-600 rounded-full cursor-pointer hover:bg-blue-100"
          >
            <svg
              className="w-5 h-5 rotate-90 rtl:-rotate-90"
              fill="#fff"
              viewBox="0 0 18 20"
            >
              <path d="m17.914 18.594-8-18a1 1 0 0 0-1.828 0l-8 18a1 1 0 0 0 1.157 1.376L8 18.281V9a1 1 0 0 1 2 0v9.281l6.758 1.689a1 1 0 0 0 1.156-1.376Z"></path>
            </svg>
            <span className="sr-only">Send message</span>
          </button>
        </div>
      </form>
      {comments.map((comment) => (
        <article
          key={comment.id}
          className="p-6 mb-6 text-base rounded-lg bg-zinc-800"
        >
          <footer className="flex justify-between items-center mb-2">
            <div className="flex items-center gap-4">
              <img
                className="rounded-full w-8"
                alt={comment.user_name + "'s avatar"}
                src={comment.picture}
              />
              <p className="inline-flex items-center font-semibold text-sm text-white">
                {comment.user_name}
              </p>
              <p className="text-sm text-gray-400">{comment.time}</p>
            </div>
          </footer>
          <p className="text-white">{comment.text}</p>
        </article>
      ))}
    </section>
  );
};

export default CommentsSection;
