import React, { useState, useEffect } from "react";
import { Button, Modal } from "flowbite-react";
import fetchSubscriptionmeta from "../../api/fetchSubscriptionmeta";

const SubscriptionCancelModal = ({ user, show, onClose, subscriptionId }) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handleCancelSubscription = async () => {
    try {
      const response = await fetchSubscriptionmeta.patch(`/${subscriptionId}`, {
        subscription_status: "cancelled",
      });

      setShowConfirmationModal(true); // Prikazuje modal nakon uspješnog odgovora
    } catch (error) {
      if (error.response) {
        console.error(
          "Error while cancelling subscription:",
          error.response.data.message
        );
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error setting up request:", error.message);
      }
    }
  };

  const handleCloseConfirmationModal = () => {
    setShowConfirmationModal(false);
    onClose(); // Može se dodatno rukovati zatvaranjem glavnog moda
    window.location.reload();
  };

  return (
    <>
      <Modal show={show} onClose={onClose}>
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <h3 className="mb-5 text-lg font-normal text-zinc-900">
              Jeste li sigurni da želite otkazati pretplatu?
            </h3>
            <div className="flex justify-center gap-4">
              <Button color="failure" onClick={handleCancelSubscription}>
                Otkaži pretplatu
              </Button>
              <Button color="gray" onClick={onClose}>
                Odustani
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Novi modal za potvrdu otkazivanja */}
      <Modal
        show={showConfirmationModal}
        onClose={handleCloseConfirmationModal}
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <h3 className="mb-5 text-lg font-normal text-zinc-900">
              Pretplata je uspješno otkazana.
            </h3>
            <div className="flex justify-center items-center">
              <Button color="gray" onClick={handleCloseConfirmationModal}>
                U redu
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SubscriptionCancelModal;
