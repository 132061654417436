import React, { useState, useEffect } from "react";
import fetchUser from "../api/fetchUser";

const Footer = ({ user }) => {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (user) {
          const response = await fetchUser.get(`/${user.id}`);
          setUserData(response.data.data.user);
        }
      } catch (err) {
        console.error("Error fetching user:", err);
      }
    };
    fetchData();
  }, [user]);

  return (
    <>
      {userData ? (
        <footer
          className="rounded-lg shadow m-4 lg:m-12 bg-zinc-800"
          id="footer"
        >
          <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
            <span className="text-sm sm:text-center text-zinc-400">
              © 2024
              <a href="https://flowbite.com/" className="hover:underline px-1">
                Better You Online Gym -
              </a>
              All Rights Reserved.
            </span>
            <ul className="flex flex-wrap items-center mt-3 text-sm font-medium text-zinc-400 sm:mt-0">
              <li>
                <a href="/terms" className="mr-4 hover:underline md:mr-6">
                  Opći uvjeti korištenja
                </a>
              </li>
            </ul>
          </div>
        </footer>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default Footer;
